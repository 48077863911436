<template>
    <div class="class-management row">
        <div class="class-room-container column">
            <class-room v-for="classroom in clasrooms" :key="classroom.id" :classroom="classroom" />
            <modal v-if="showModal" :click-handler="createClassRoom"
                :text-button="$t('create')"  @close="showModal = false">
                    <new-class-room/>
            </modal>
            <button class="btn secondary" @click="showModal = true">{{$t('addClass')}}</button>
        </div>
        <class-room-panel />
    </div>
</template>
<script>
import ClassRoom from '@/components/ClassRoomManagement/ClassRoom.vue'
import NewClassRoom from '@/components/ClassRoomManagement/NewClassRoom.vue'
import ClassRoomPanel from '@/components/ClassRoomManagement/ClassRoomPanel.vue'
import Modal from '@/common/Modal.vue'
import moment from "moment";

export default {
    name: 'ClassRoomManagement',
    components:{
        ClassRoom, 
        NewClassRoom, 
        ClassRoomPanel,
        Modal
    },
    data(){
        return{
            showModal: false
        }
    },
    computed:{
        clasrooms(){
            return this.$store.state.classrooms
        },
        newClassroom(){
            return this.$store.state.newClassroom
        }, 
        selectedClassroom(){
            return this.$store.state.selectedClassroom
        },
        selectedTeacher(){
            return this.$store.state.newClassRoomSelectedTeacher;
        }
    },
    methods:{
        createClassRoom(){
            let startDate = moment(this.newClassroom.startDate);
            let endDate = moment(this.newClassroom.endDate);

            if(!this.newClassroom.name 
                || startDate.isSame(endDate) || endDate.isBefore(startDate)
                || !this.selectedTeacher){
                this.$store.commit('addSnackBarNotification', {
                    message: "Some fields are still empty or have invalid data", 
                    dismissable:true
                });

                return;
            }

            this.$store.dispatch('addClassRoom', { 
                ...this.newClassroom,
                startDate: startDate.format(),
                endDate: endDate.format(),
                teacherUserId: this.selectedTeacher.id,
                schoolId: this.selectedTeacher.schoolId
                });

            this.showModal= false;
        }
    },
    created(){
        this.$store.commit('updateSelectedClassroom', undefined)
        this.$store.commit('loadClassRooms', undefined)
        this.$store.dispatch('loadClassRooms')
    },
    mounted(){
        this.startTrackingPage({ sectionName: "ClassRoom Management Dashboard" });
        this.$store.commit("setAnalyzedManagementSection", "ClassRoom Management Dashboard");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "ClassRoom Management Dashboard" });
    }
}
</script>
<style lang="less">
.class-management{
    width: 968px;
    margin: 0  auto;
    margin-top: 104px;
    position: relative;
}
.class-room-container{
    width: 960px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 60px;
    >.btn{
        margin: auto;
        margin-top: 24px;
    }
}
</style>

