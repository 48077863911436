import { render, staticRenderFns } from "./ClassRoomPanel.vue?vue&type=template&id=1dc4aa3c&scoped=true&"
import script from "./ClassRoomPanel.vue?vue&type=script&lang=js&"
export * from "./ClassRoomPanel.vue?vue&type=script&lang=js&"
import style0 from "./ClassRoomPanel.vue?vue&type=style&index=0&id=1dc4aa3c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc4aa3c",
  null
  
)

component.options.__file = "ClassRoomPanel.vue"
export default component.exports